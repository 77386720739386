import React from 'react';

const Header = () => {
    return (
        <header id="menu">
             <nav className="navbar navbar-expand-lg navbar-dark bg-dark" id="templatemo_nav_top">
                <div className="container d-flex justify-content-between align-items-center">
                <a className="navbar-brand text-white logo h1 align-self-center" href="/">
                        FindCoupon
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#templatemo_main_nav"
                        aria-controls="templatemo_main_nav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse align-self-center flex-fill  d-lg-flex justify-content-lg-between"
                        id="templatemo_main_nav">
                        <div className="flex-fill">
                        <ul className="nav navbar-nav d-flex justify-content-between mx-lg-auto text-white">
                                <li className="nav-item dropdown">
                                    <a className="h3 nav-link  text-white  dropdown-toggle"
                                        href="#" data-bs-toggle="dropdown">Stores</a>
                                    <ul className="dropdown-menu h3 bg-white ">
                                        <li><a className="dropdown-item h3 bg-white " href="/store/amazon/coupons"> Amazon</a></li>
                                        <li><a className="dropdown-item h3 bg-white " href="/store/michaels/coupons"> Micheals </a></li>
                                        <li><a className="dropdown-item h3 bg-white " href="/store/tigerdirect/coupons"> TigerDirect</a></li>
                                    </ul>
                                </li>
                
                                <li className="nav-item">
                                    <a className="h3 nav-link text-white" href="/blog">Blog</a>
                                </li>
                                <li className="nav-item">
                                    <a className="h3 nav-link text-white" href="/about">About Us</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;