import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-dark" id="tempaltemo_footer">
            <div className="w-100 bg-black py-3">
                <div className="container">
                    <div className="row pt-2">
                        <div className="col-12">
                        <p className="text-left text-light">
                                Copyright &copy; 2021 FindCoupon.net
                            |<a rel="noreferrer" target="_blank" href="https://icons8.com/icons/set/dove">Dove icon </a> icon by  
                            <a rel="noreferrer" target="_blank" href="https://icons8.com" > Icons8</a> | As an affilate, I earn from qualifying purchases. | Content or Coupons that appears on this site comes from Amazon Services LLC. This content is provided "as is" and is subject to change or removal at any time.
                        </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        
    );
}
export default Footer;