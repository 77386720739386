import React from 'react';
import Carousel from './Carousel.js';
import TrendingDeal from './TrendingDeal.js';
import LaptopDeal from './LaptopDeal.js';

const MainHome = () => {
    return (
        <main id="mainContent" className="bg-white">
            <div className="container bg-white">
            <TrendingDeal />
            <Carousel />
            <LaptopDeal />
            </div>
        </main>
    );
}

export default MainHome;