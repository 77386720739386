import React from 'react';
import trendingData from './data/trending.json'

const LaptopDeal = () => {
  return (
    <div className="row p-1 border-top">
      <div className="row px-4 pt-3">
        <h5>Laptop Deals</h5>
        {
          trendingData.map((deals) =>
            <div className="col-md-3">
              <div className="card mb-4 product-wap">
                <a href={deals.affliateURL} target="_blank" rel="noreferrer">
                  <div className="card py-1">
                    <img className="card-img rounded-0 img-fluid" src={deals.imageURL} alt={deals.name} />
                  </div>
                  <div className="card-body text-center">
                    <p className="h6 text-dark">{deals.name.substring(0, 120)}</p>
                    <div className="product-price text-success font-weight-bold"> {deals.salePrice}&nbsp;<del className="text-muted">{deals.originalPrice}</del></div>
                  </div>
                </a>
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
}
export default LaptopDeal;