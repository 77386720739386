import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import React, { Suspense } from 'react';

import { Helmet } from 'react-helmet';

import MainHome from './components/home/MainHome.js'
import Header from './components/Header.js'
import Footer from './components/Footer.js'

const About = React.lazy(() => import('./components/About.js'));

const MichaelsHome = React.lazy(() => import('./components/store/michaels/MichaelsHome.js'));

const TigerDirectLaptopDeal = React.lazy(() => import('./components/store/tigerdirect/TigerDirectLaptopDeal.js'));

const Blog = React.lazy(() => import('./components/blog/Blog.js'));
const BlogArticle = React.lazy(() => import('./components/blog/BlogArticle.js'));

const Stores = React.lazy(() => import('./components/store/Stores.js'));

const AmazonHome = React.lazy(() => import('./components/store/amazon/AmazonHome.js'));

const AmazonCoupon = React.lazy(() => import('./components/store/amazon/AmazonCouponCategory.js'));


class App extends React.Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Helmet>
            <title>Find coupons and deals on products from Amazon, BestBuy, Walmart and more</title>
            <meta name="description" content="Find coupons and deals on products from Amazon, BestBuy, Walmart and more" />
            <meta name="keywords"
              content="amazon coupon codes, code, discount, Amazon coupons, bonus code, promotional, promo codes, amazon.com coupon, bestbuy Clearance, phone case deals" />
            <meta property="og:title" content="Find coupons and deals on products from Amazon, BestBuy, Walmart and more" />
            <meta property="og:type" content="company" />
            <meta property="og:url" content="https://findcoupon.net/" />
            <meta property="og:site_name" content="FindCoupon" />
            <meta property="og:description" content="Latest coupons for beauty and spring sunglasses are available now" />
          </Helmet>
          <Header />
          <div id="page-body">
            <Route path="/" component={MainHome} exact />
            <Suspense fallback={<div>Loading...</div>}>
              <Route path="/about" component={About} />
              <Route path="/coupons" component={Stores} exact />
              <Route path="/blog" component={Blog} exact />
              <Route path="/blog/:article" component={BlogArticle} exact />
              <Route path="/store/amazon/coupons" component={AmazonHome} exact />
              <Route path="/store/amazon/coupons/:category" component={AmazonCoupon} exact />
              <Route path="/store/tigerdirect/coupons" component={TigerDirectLaptopDeal} />
              <Route path="/store/michaels/coupons" component={MichaelsHome} />
              <Route path="/store/walmart/coupons" component={AmazonHome} />
              <Route path="/store/bestbuy/coupons" component={AmazonHome} />
            </Suspense>
          </div>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;